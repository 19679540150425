import React from "react";
import { increment, decrement } from "../../reducer/actionTypes"
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import "./slider.styles.scss";
import useKeyMove from "../../hooks/useKeyMove";

const Slider = ({ slides, dispatch, renderSliderContentContainer }) => {

    //TODO Swipe add on with finger could be added
    useKeyMove(dispatch);

    return (
        <div className="slider">
            <MdKeyboardArrowLeft className="slider__left-arrow" onClick={() => dispatch({ type: decrement })} />
            <MdKeyboardArrowRight className="slider__right-arrow" onClick={() => dispatch({ type: increment })} />
            {slides.map((slide, index) => {
                return (
                    renderSliderContentContainer(slide, index)
                )
            })}
        </div>
    )
}

export default Slider;
