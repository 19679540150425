import React, { useContext, useEffect } from "react";
import "./imageGallery.styles.scss";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ImageModalWrapper from "../imageModalWrapper/ImageModalWrapper";
import Modal from "../modal/Modal";
import { ImageModalContext } from "../../provider/ImageModalProvider";
import { SliderIndexDispatchContext, SliderIndexStateContext } from "../../provider/SliderIndexProvider";
import { onSelect, setLength } from "../../reducer/actionTypes";

const ImageGallery = ({ images }) => {

  const { state } = useContext(SliderIndexStateContext);
  const { dispatch } = useContext(SliderIndexDispatchContext);
  const { isOpen, handleIsOpen, handleIsOpenModal, setIsOpen } = useContext(ImageModalContext);

  useEffect(() => dispatch({ type: setLength, payload: images.length }), []);

  return (
    <div className="image-gallery">
      <Modal isOpen={isOpen} handleIsOpenModal={handleIsOpenModal} setIsOpen={setIsOpen} >
        <ImageModalWrapper slides={images} handleIsOpen={handleIsOpen} sliderIndex={state.count} />
      </Modal>
      {images.map((item, index) => {
        return (
          <div className="image-gallery__item" key={index} onClick={() => {
            dispatch({ type: onSelect, payload: index });
            handleIsOpen();
          }}>
            <GatsbyImage className="image" objectFit="cover" image={getImage(item.img)} alt={item.alt} />
          </div>)
      })}
    </div>
  )
}

export default ImageGallery;
