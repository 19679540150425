import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { useState } from "react";
import Button from "../button/Button";
import ImageBackground from "../imageBackground/ImageBackground";
import "./map.styles.scss";

const data = graphql`
query MapImage {
imageSharp(resize: {originalName: {eq: "map.jpg"}}) {
  gatsbyImageData(formats: WEBP)
}
}
`

const Map = ({ location, fullWidth }) => {

  const [acceptMapsCookies, setAcceptMapsCookies] = useState(false);
  const { imageSharp } = useStaticQuery(data);

  return (
    <div className={`map__container${fullWidth ? " full-width" : ""}`}>
      {!acceptMapsCookies ?
        <ImageBackground
          img={imageSharp}
          imgAlt="world-map-placeholder"
          renderContent={() =>
            <>
              <Button btnText="Karte anzeigen" onClick={() => setAcceptMapsCookies(true)} />
              <p style={{ marginTop: "1rem" }}>Durch Aktivierung der Karte werden von Google Maps Cookies gesetzt. Mehr dazu in unserem Datenschutzhinweis.</p>
            </>
          } />
        :
        <iframe
          title="google-maps"
          loading="lazy"
          allowFullScreen
          className="map"
          src={location}></iframe>}
    </div>
  );
};

export default Map;
