import React from "react";
import { getImage } from "gatsby-plugin-image"
import { BgImage, convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'
import "./imageBackground.styles.scss";

const ImageBackground = ({ renderContent, img, imgAlt }) => {
    const image = convertToBgImage(getImage(img));
    return (
        <BackgroundImage
            {...image}
            className="image-background"
            alt={imgAlt}
        >
            <div className="image-background__filter"></div>
            {renderContent()}
        </BackgroundImage>
    )
}

export default ImageBackground;

