import React from "react";
import "./sliderSingleContentContainer.styles.scss";

const SliderSingleContentContainer = ({ index, state, renderSliderContent, slide }) => {
    return (
        <div className={state.count === index ? "slider__content active" : "slider__content"}>
            {state.count === index &&
                renderSliderContent(slide)
            }
        </div>
    )
}

export default SliderSingleContentContainer;
