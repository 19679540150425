export const form = [
    {
        label: "Vorname",
        type: "text",
        name: "firstName",
        placeholder: "Vorname eingeben",
        validation: {
            required: {
                value: true,
                message: "Bitte fülle dieses Feld aus."
            }
        },
    },
    {
        label: "Nachname",
        type: "text",
        name: "lastName",
        placeholder: "Nachname eingeben",
        validation: {
            required: {
                value: true,
                message: "Bitte fülle dieses Feld aus."
            }
        },
    },
    {
        label: "Email",
        type: "email",
        name: "email",
        placeholder: "Email eingeben",
        validation: {
            required: {
                value: true,
                message: "Bitte fülle dieses Feld aus."
            },
            pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Bitte gebe eine valide Email an."
            }
        },
    },
    {
        label: "Telefon",
        type: "text",
        name: "phone",
        placeholder: "Telefonnummer eingeben",
        validation: {
            required: {
                value: false,
                message: "Bitte fülle dieses Feld aus."
            }
        },
    },
    {
        label: "Website",
        type: "text",
        name: "website",
        placeholder: "Link zu Deiner Website angeben",
        validation: {
            required: {
                value: false,
                message: "Bitte fülle dieses Feld aus."
            },
            pattern: {
                value: /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
                message: "Bitte gebe eine valide Website Url an."
            }
        },
    },
    {
        label: "Deine Anfrage",
        type: "textarea",
        name: "request",
        placeholder: "Beschreibe bitte kurz Dein Anliegen",
        validation: {
            required: {
                value: true,
                message: "Bitte fülle dieses Feld aus."
            }
        },
    },
    {
        label: "Wir verwenden Deine persönlichen Daten nur, um das kostenlose und unverbindliche Beratungsgespräch durchzuführen sowie Dich ggf. über unsere Inhalte zu informieren. Durch Deine Zustimmung versicherst Du, dass Du unsere Datenschutzerklärung zur Kenntnis genommen und akzeptiert hast. Die Einwilligung zur Verarbeitung Deiner persönlichen Daten kannst Du jederzeit bei uns widerrufen.",
        type: "checkbox",
        name: "terms",
        placeholder: null,
        validation: {
            required: {
                value: true,
                message: "Bitte stimme zu, um fortzufahren."
            }
        },
    },
]

// export { form };

export const infoText = "Bitte fülle dieses kurze Formular aus, damit wir wissen, wie wir Dir am besten helfen können:";

export const btnText = "Anfrage senden"

export const confirmationText = "Vielen Dank für Dein Interesse an unserer Dienstleistung. Deine Nachricht wurde uns übermittelt, wir melden uns innerhalb von 24h bei Dir."

export const errorText = "Es ist ein Fehler beim Versenden aufgetreten. Bitte versuche es noch einmal oder versuche uns auf einem anderen Wege zu kontaktieren. Danke."