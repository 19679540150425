import { useEffect } from "react";
import { increment, decrement } from "../reducer/actionTypes";

function useKeyMove(dispatch) {

    useEffect(() => {
        const move = (e) => {
            if (e.keyCode === 37) {
                dispatch({ type: decrement });
            };

            if (e.keyCode === 39) {
                dispatch({ type: increment });
            };
        }
        window.addEventListener('keydown', move)
        return () => window.removeEventListener('keydown', move)
    }, [])
}

export default useKeyMove;