import React, { useContext } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { ContactForm, socialMediaIcons } from "../../constants/contactLinks";
import { IconMatcher } from "../../utils/IconMatcher";
import { ContactModalDispatchContext } from "../../provider/ContactModalProvider";
import "./contactBanner.styles.scss";

const ContactBanner = () => {

    const data = useStaticQuery(graphql`
    query {
        allContentfulSocialMediaLinks {
            nodes {
                url
                platform
            }
        }
    }
    `
    )

    const { handleIsOpen } = useContext(ContactModalDispatchContext);

    let allSocialMediaLinks = IconMatcher(data.allContentfulSocialMediaLinks.nodes, socialMediaIcons);

    allSocialMediaLinks.push(ContactForm(handleIsOpen));

    return (
        <div className="contact-banner">
            {allSocialMediaLinks.map((item, index) => {
                let Icon = item.icon;
                return <a aria-label={item.platform} target="_blank" rel="noopener noreferrer" className="contact-banner__item" key={index} href={item.url} onClick={item.onClick} ><Icon /></a>
            })}
        </div>
    )
}

export default ContactBanner;
