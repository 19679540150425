import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import "./detailImage.styles.scss";

const DetailImage = ({img, alt, description, link}) => {
    return (
        <div className="img-modal__content">
            <div className="img-modal__img__container">
                <GatsbyImage image={getImage(img)} alt={alt} objectFit="contain" className="img-modal__img" />
            </div>
            <div className="img-modal__info__container">
                {description && <p>{description}</p>}
                {link && <a href={link}>{link}</a>}
            </div>
        </div>
    )
}

export default DetailImage
