import { useEffect } from "react";

function useKeyEsc(setIsOpen) {

    return useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                setIsOpen(false);
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [])
}

export default useKeyEsc;