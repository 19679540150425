import React, { useRef } from "react";
import useKeyEsc from "../../hooks/useKeyEsc";
import "./modal.styles.scss";

const Modal = ({ isOpen, children, handleIsOpenModal, setIsOpen }) => {


    const modalRef = useRef();

    useKeyEsc(setIsOpen);

    return (
        <div ref={modalRef} className={`modal${isOpen ? " open" : ""}`} onClick={(e) => handleIsOpenModal(e, modalRef)}>
            {isOpen && children}
        </div>

    )
}

export default Modal;
