import React, { useContext } from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { BLOCKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import ImageGallery from "../components/imageGallery/ImageGallery";
import ContactBanner from "../components/contactBanner/ContactBanner";
import Layout from "../components/layout/Layout";
import TextCard from "../components/textCard/TextCard";
import Button from "../components/button/Button";
import { AiOutlineCheck } from "react-icons/ai";
import Modal from "../components/modal/Modal";
import { form, btnText, infoText, confirmationText, errorText } from "../constants/formData";
import Map from "../components/map/Map";
import { ContactModalStateContext, ContactModalDispatchContext } from "../provider/ContactModalProvider";
import ActionSteps from "../components/actionSteps/ActionSteps";
import Head from "../components/head/Head";
import ImageBackground from "../components/imageBackground/ImageBackground";
import { ImageTextCard } from "../components/imageTextCard/ImageTextCard";
//import Form from "../components/form/Form";

const Form = React.lazy(() => import("../components/form/Form"))



const firstOptions = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
    [BLOCKS.LIST_ITEM]: (node, children) => <li className="custom-list"><div style={{ paddingTop: "5px" }}><AiOutlineCheck size={25} /></div>{children}</li>,
  }
}

function IndexPage({ data: { allContentfulCardText, allContentfulImgGallery, allContentfulActionSteps, allContentfulLocation } }) {

  let actionSteps = { actionStep: allContentfulActionSteps.nodes, pageOrder: allContentfulActionSteps.nodes[0].pageOrder, type: allContentfulActionSteps.nodes[0].type };

  let mapLocation = allContentfulLocation.nodes[0].googleIframeSource + process.env.GATSBY_GOOGLE_MAPS_IFRAME_API_KEY;

  let imgGallery = allContentfulImgGallery.nodes;

  let pageData = [...allContentfulCardText.nodes, actionSteps].sort((a, b) => a.pageOrder - b.pageOrder)

  const Hero = ({ data, color = "" }) => {

    const { handleIsOpen } = useContext(ContactModalDispatchContext)

    return (
      <ImageBackground img={data.image} imgAlt={data.name} renderContent={() =>
        <section className={`page__element ${color}`.trim()}>
          <div>
            <TextCard
              title={data.title}
              subTitle={data.subTitle}
              cardText={renderRichText(data.text, firstOptions)}
            />
          </div>
          <div className="box-pad-s">
            <Button btnText="Kontakt" onClick={handleIsOpen} />
          </div>
        </section>
      } />)
  }

  const Section = ({ data, color = "", imgLeft = false }) => (
    <section className={`page__element ${color}`.trim()}>
      <ImageTextCard
        title={data.title}
        img={data.image}
        imgAlt={data.name}
        subTitle={data.subTitle}
        cardText={renderRichText(data.text, firstOptions)}
        vertical={false}
        imgLeft={imgLeft} />
    </section>
  )

  const Expose = ({ data, color = "" }) => (
    <section className={`page__element ${color}`.trim()}>
      <TextCard
        title={data.title}
        subTitle={data.subTitle}
        cardText={renderRichText(data.text, firstOptions)} />
      <ImageGallery images={imgGallery} />
    </section>
  )

  const JoinedActionSteps = ({ data, color = "" }) => {
    const { handleIsOpen } = useContext(ContactModalDispatchContext)

    return (
      <section className={`page__element horizontal ${color}`.trim()}>
        <ActionSteps actions={data} />
        <div className="box-pad-s">
          <Button btnText="Kontakt" onClick={handleIsOpen} />
        </div>
      </section>
    )
  }

  const Location = ({ data, color = "" }) => (
    <section className={`page__element ${color}`.trim()}>
      <h1>{data.title}</h1>
      <h2>{data.subTitle}</h2>
      <GatsbyImage
        image={getImage(data.image)}
        alt={data.name}
        objectFit="contain"
        style={{ marginBottom: "20px" }}
      />
      <Map location={mapLocation} />
    </section>
  )

  const FormWithModal = () => {
    const { isOpen } = useContext(ContactModalStateContext)
    const { setIsOpen, handleIsOpenModal, handleIsOpen } = useContext(ContactModalDispatchContext)

    return (
      <Modal isOpen={isOpen} handleIsOpenModal={handleIsOpenModal} setIsOpen={setIsOpen} >
        <React.Suspense fallback={<div>Loading...</div>}>
          <Form
            handleIsOpen={handleIsOpen}
            form={form}
            infoText={infoText}
            btnText={btnText}
            confirmationText={confirmationText}
            errorText={errorText}
          />
        </React.Suspense>
      </Modal >)
  }

  const conditionalColor = (index) => index % 2 ? "secondary-color" : ""

  return (
    <div className="page__body">
      <Layout>
        <Head title="Fotografie und Videografie mit gustavgusto | Frankfurt" titleSuffix={false} robots="index,follow" />
        <main>
          <ContactBanner />
          {pageData.map((pageSection, index) => {
            if (pageSection.type === "hero") return <Hero key={pageSection.pageOrder} data={pageSection} color={conditionalColor(index)} />
            if (pageSection.type === "section") return <Section key={pageSection.pageOrder} data={pageSection} color={conditionalColor(index)} imgLeft={index % 2 ? false : true} />
            if (pageSection.type === "expose") return <Expose key={pageSection.pageOrder} data={pageSection} color={conditionalColor(index)} />
            if (pageSection.type === "actionstep") return <JoinedActionSteps key={pageSection.pageOrder} data={pageSection.actionStep} color={conditionalColor(index)} />
            if (pageSection.type === "location") return <Location key={pageSection.pageOrder} data={pageSection} color={conditionalColor(index)} />
          })}
          <FormWithModal />
        </main>
      </Layout>
    </div>
  )
}

export const query = graphql`
  query {
      allContentfulCardText {
        nodes {
          subTitle
          title
          image {
            gatsbyImageData(placeholder: BLURRED, formats: WEBP)
          }
          text {
            raw
          }
          type
          pageOrder
        }
      }
      allContentfulImgGallery {
      nodes {
        img {
          gatsbyImageData(placeholder: BLURRED, formats: WEBP)
        }
        alt
        description
        #link
      }
    }
    allContentfulActionSteps(sort: {fields: order, order: ASC}) {
      nodes {
        title
        text {
          raw
        }
        image {
          gatsbyImageData(placeholder: BLURRED, formats: WEBP)
          title
        }
        order
        type
        pageOrder
      }
    }
    allContentfulLocation {
      nodes {
        googleIframeSource
      }
    }
  }
`

export default IndexPage;
