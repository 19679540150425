import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { BLOCKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import "./actionSteps.styles.scss";

const firstOptions = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
    }
}


const ActionSteps = ({ actions, alignCenter = false }) => {
    return (
        <div className="action-steps">
            {actions.map((action, index) =>
                <div key={index} className="action-step__container">
                    <div className="action-step__image__container">
                        <GatsbyImage image={getImage(action.image)} alt={action.image.title} objectFit="contain"className="action-step__image" />
                    </div>
                    <div className={`action-step__text__container${alignCenter ? " center" : ""}`}>
                        <h2>{action.title}</h2>
                        {renderRichText(action.text, firstOptions)}
                    </div>
                </div>
            )}
        </div>
    )
}

export default ActionSteps;
