import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import TextCard from "../textCard/TextCard";
import "./imageTextCard.styles.scss";

export const ImageTextCard = ({
    img,
    imgTop: vertical,
    imgLeft: imgFirst,
    imgAlt,
    transparent = true,
    box,
    alignCenter = false,
    titleAttribute,
    title,
    subTitleAttribute,
    subTitle,
    cardText }) => {

    return (
        <div className={`img-card-text${vertical ? " top" : ""}${transparent ? " transparent" : ""}${box ? " box" : ""}`}>
            <div className={`img-card-text__img__container${imgFirst ? " first" : ""}`}>
                <GatsbyImage
                    image={getImage(img)}
                    alt={imgAlt}
                    className="img-card-text__img"
                    objectFit="contain"
                />
            </div>
            <TextCard
                title={title}
                titleAttribute={titleAttribute}
                subTitle={subTitle}
                subTitleAttribute={subTitleAttribute}
                cardText={cardText}
                alignCenter={alignCenter} />
        </div>
    )
}
