import React, { useContext } from 'react'
import Slider from '../slider/Slider';
import { AiOutlineClose } from "react-icons/ai";
import { SliderIndexDispatchContext, SliderIndexStateContext } from "../../provider/SliderIndexProvider";
import SliderSingleContentContainer from '../sliderSingleContentContainer/SliderSingleContentContainer';
import "./imageModalWrapper.styles.scss";
import DetailImage from '../DetailImage/DetailImage';

const ImageModalWrapper = ({ slides, handleIsOpen, sliderIndex }) => {

    //TODO Check how you can improve modularity structure and remove nested renderProps

    const { state } = useContext(SliderIndexStateContext);
    const { dispatch } = useContext(SliderIndexDispatchContext);

    return (
        <div className="img-modal-wrapper">
            <button className="close__btn">
                <AiOutlineClose onClick={handleIsOpen} />
            </button>
            <div className="img-modal-wrapper__content">
                <Slider
                    slides={slides}
                    sliderIndex={sliderIndex}
                    state={state}
                    dispatch={dispatch}
                    renderSliderContentContainer={(slide, index) =>
                        <SliderSingleContentContainer key={index} index={index} slide={slide} state={state} renderSliderContent={(item) =>
                            <DetailImage img={item.img} alt={item.alt} description={item.description} link={item.link} />} />}
                />
            </div>
        </div>
    )
}

export default ImageModalWrapper;
