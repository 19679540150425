import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import "./button.styles.scss";


const Button = ({
  btnText = "Button Text",
  negative,
  secondaryColor,
  outline,
  disabled,
  isLoading,
  onClick,
}) => {


  return (
      <button disabled={disabled} className={`button${negative ? " negative" : ""}${secondaryColor ? " secondary-color" : ""}${outline ? " outline" : ""}${disabled ? " disabled" : ""}`} onClick={onClick}>
        {!isLoading ? btnText : <ClipLoader className="spinner" size={30} />}
      </button>
  );
};

export default Button;
